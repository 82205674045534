<template>
  <div class="row">
    <retry-notification
      :show="error"
      @retry="updateData()"
    />
    <div class="flex xs12">
      <resume-table
        :edit="true"
        :report="report"
        :loading="loading"
      />
    </div>
    <div class="flex xs12">
      <va-card>
        <form @submit.prevent="submit">
          <div class="row">
            <div class="flex xs12">
              <va-input
                color="info"
                v-model="form.observations.value"
                type="textarea"
                :autosize="true"
                :min-rows="5"
                :disabled="loading"
                :label="$t('reports.inputs.observations')"
              />
            </div>
          </div>
          <div class="row">
            <div class="flex xs12 sm2">
              <va-button
                color="primary"
                type="submit"
                :disabled="loading"
              >
                <text-loading
                  icon="fa fa-floppy-o"
                  :disabled="loading"
                  :loading="loading"
                >
                  {{ $t('layout.form.save') }}
                </text-loading>
              </va-button>
            </div>
            <div
              class="flex xs12 sm4"
              v-if="canReview"
            >
              <va-button
                color="success"
                type="button"
                :disabled="loading"
                @click.prevent="saveAndReview(1)"
              >
                <text-loading
                  icon="fa fa-check"
                  :loading="loading"
                >
                  {{ $t('reports.inputs.review.country.approve') }}
                </text-loading>
              </va-button>
            </div>
          </div>
        </form>
      </va-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { validatorMixin } from '@/services/validator'
const ResumeTable = () => import(/* webpackPrefetch: true */ './Table')

export default {
  name: 'country-report-edit',
  components: {
    ResumeTable,
  },
  mixins: [validatorMixin],
  computed: {
    ...mapGetters(['currentUser']),
    canReview () {
      return this.currentUser.can('CountryReports', 'approve')
    },
  },
  data () {
    return {
      error: false,
      loading: false,
      report: {},
      approveReport: {
        status: false,
        value: 0,
      },
      form: {
        observations: {
          value: '',
          validate: {},
          errors: [],
        },
      },
    }
  },
  created () {
    this.updateData()
  },
  methods: {
    // FIXME
    routeBuilder (id) {
      return `reports/countries/${id}`
    },
    isValidated (fieldId) {
      let isValid = false
      if (this.formFields[fieldId]) {
        isValid = this.formFields[fieldId].validated && this.formFields[fieldId].valid
      }
      return isValid
    },
    async updateData () {
      this.loading = true
      this.error = false

      let u = false
      const reportId = this.$route.params.id
      try {
        u = await this.$http.get(this.routeBuilder(reportId))
      } catch (err) {
        // console.log('Error fetching country data', err)
        this.error = true
        this.loading = false
        return
      }

      this.loading = false
      this.report = u.data.data
      this.setFormValue('observations', this.report.observations || '')
    },
    async submit () {
      const observations = this.getFormValue('observations')
      const submitReport = {
        id: this.report.id,
        observations: observations,
      }

      this.loading = true
      try {
        await this.$http.put(this.routeBuilder(submitReport.id), submitReport)
      } catch (error) {
        // console.log('Error updating data', error)
        this.loading = false
        return
      }

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
      this.$router.push({ name: 'reportsIndex' })
    },
    async saveAndReview (approve) {
      this.loading = true
      const reportId = this.$route.params.id
      const body = {
        approve: approve,
      }
      const approveStatus = approve === 1 ? 'approved' : 'rejected'

      try {
        await this.$http.post(`reports/countries/approve/${reportId}`, body)
      } catch (error) {
        // console.log('Approve error', error)
        this.loading = false
        return
      }

      this.showToast(this.$t('reports.notifications.' + approveStatus), {
        icon: approveStatus === 'approved' ? 'fa-check' : 'fa-times',
        position: 'top-right',
      })
      this.loading = false
      this.submit(this.report)
    },
  },
}
</script>
